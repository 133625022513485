import axios from 'axios';

const BASE_URL = 'https://vdpsupport.homeonetechnologies.in/api/tempLog'; // Replace with your API base URL
const Base_URL_VDP = 'https://vdpsupport.homeonetechnologies.in/api/vdpState';


export const FetchData = async (data) => {
    try {
        let mainData = {
            "hardwareId":data
        }

        const response = await axios.post(`${BASE_URL}`, mainData);
        console.log("--------temp Data--------",response);

        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};



export const VdpFetch = async (data) => {

    try {
        let mainData = {
            "reqDevices":data
        }
        console.log("--------inside vdpFetch Function--------");

        const response = await axios.post(`${Base_URL_VDP}`, mainData);
        console.log("--------vdp Details--------",response.data);

        return response.data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};



