// import { Routes, Route } from 'react-router-dom';
import Home from '../src/screen/dashboard'


function App() {
  return (
    <div className="app">
      <main className="content">
        <Home />

        {/* <Routes>
          <Route path="/" />
        </Routes> */}

      </main>
    </div>
  );
}

export default App;
