import React, { useState, useEffect } from "react";
import { Box, Typography, TextField, Button } from "@mui/material"
import { FetchData, VdpFetch } from "../../data/tempData"
import { DataGrid } from '@mui/x-data-grid'
import vdpFirebaseData from '../../data/vdpFirebaseData'
// import { DataGrid } from '@mui/x-data-grid'


function Home() {

    let [data, setData] = useState()
    let [setRes, setResData] = useState("")
    let [tempRes, setTempRes] = useState("")

    // useEffect(() => {
    //     // console.log("----------after set value ---------", setRes);
    // });
    let column1 = [
        // { field: "id", headerName: "ID" },
        { field: "internalTemp", headerName: "Internal Temp", flex: 0.4 },
        { field: "fanState", headerName: "Fan State", flex: 0.2 },
        { field: "date", headerName: "Date", flex: 1 }
        // { field: "hardwareId", headerName: "HardwareId", flex: 1 },

    ]
    let column = [
        // { field: "id", headerName: "ID" },
        { field: "id", headerName: "ID", flex: 0.8 },
        { field: "mac_id", headerName: "Mac Id", flex: 0.8 },
        { field: "version", headerName: "Version", flex: 0.4 },
        { field: "ssid", headerName: "ssid", flex: 1 },
        { field: "ip_address", headerName: "IP_Address", flex: 1 },
        { field: "wifiSignalStrength", headerName: "wifiSignalStrength", flex: 0.4 }
    ]

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevents the default form submission behavior
        try {

            const response = await FetchData(data);
            await setTempRes(response)

            const vdpDetails = await VdpFetch(data)
            await setResData(vdpDetails)

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    return (
        <Box >
            <Box display="flex" justifyContent="center" alignItems="center" m="10px 50px 0 0">
                <Typography variant="h6">
                    Hello Welcome to Home page
                </Typography>
            </Box>
            <Box display="flex" justifyContent="center" alignItems="center" m="20px">
                <form onSubmit={handleSubmit}>
                    {/* <Box display="flex" justifyContent="space-between" > */}
                    <TextField
                        required
                        id="outlined-required"
                        label="Device ID"
                        value={data}
                        onChange={(e) => setData(e.target.value)}
                    />
                    <Button type="submit" variant="contained" color="primary" sx={{ margin: "10px" }}>
                        Submit
                    </Button>

                    {/* </Box> */}

                </form>
            </Box>

            {/* onSubmit={<FetchData data={data} />} */}


            <Box m="40px 0 0 0" height="75vh" display="flex" justifyContent="space-between"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },
                    "& .name-column--cell": {
                        color: "darkBlue",
                    },
                    "& .MuiDataGrid-columnHeader": {
                        backgroundColor: "lightGray",
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScrollbar": {
                        backgroundColor: "gray",
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: "lightGray",
                    }
                }}
            >
                <Box m="50px 1% 50px 120px" width="1500px">
                    <DataGrid rows={setRes} columns={column} sx={{ backgroundColor: "darkgray" }} />
                </Box>
                <Box m="50px 120px 50px 50px" width="900px">
                    <DataGrid rows={tempRes} columns={column1} sx={{ backgroundColor: "darkgray" }} />
                </Box>

            </Box>


        </Box>


    )
}

export default Home;
